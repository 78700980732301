export const load = ( props ) => {
    props.getCompanies()
    props.getUserCards()
    props.getUserContacts()
}

export const prepare = raw => {
    const
        res = { id: raw.id, }

    if ( raw.first_name !== void 0 ) {
        res.name = `${raw.first_name || ''} ${raw.last_name || ''}`.trim()
        res.image = raw.avatar
        ;( !res.name ) && ( res.name = 'Account' )
    } else {
        res.id = raw.company.id
        res.name = raw.company.name
        res.image = raw.company.square_logo
    }

    return res
}

/* eslint-disable no-case-declarations */
import { useEffect, useState }      from 'react'
import { useParams, useHistory }    from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Center, PublicLoader, StaticPage, PublicCardHeader, PublicCardFooter } from 'components'
import { publicActions }                                                        from 'services'
import { t, adjust }                                                            from 'tools'
import config                                                                   from 'config'

const CardByCode = () => {
    const
        { id } = useParams(),
        [ error, $error ] = useState( false ),
        search = useSelector( state => state.public.search ),
        created = useSelector( state => state.public.precreated ),

        history = useHistory(),
        dispatch = useDispatch(),

        parseUID = raw => BigInt( parseInt( raw, 36 )).toString(),

        createNewCard = () => {
            const
                uid = parseUID( id ),

                urlParams = new URLSearchParams( window.location.search ),
                design = urlParams.get( 'd' ),
                type = urlParams.get( 't' )

            dispatch( publicActions.precreateCard({
                appv:           '1.1',
                platform:       '3',
                project:        '73e9b050-3259-11ec-8038-6a13bef2453b',
                company_design: design || '',
                type:           type || 'id-card',
                uid
            }))
        }

    useEffect(() => {
        if ( !id ) {
            window.location.href = config.sso.url
            return
        }

        dispatch( publicActions.searchCardByUID( parseUID( id )))
    }, [])

    useEffect(() => {
        if ( search ) {
            if ( search.error && +search.status !== 404 ) {
                $error( true )
            } else if ( search.length < 1 || ( search.error && +search.status === 404 )) {
                createNewCard()
            } else {
                dispatch( publicActions.flushSearchCard())
                history.push( `/p/${search[ 0 ].id}` )
            }
        }
    }, [ search ])

    useEffect(() => {
        if ( created && created.card?.id ) {
            adjust.track( 'create_card_by_code' )
            history.push( `/p/${created.card.id}` )
        }
    })

    return (
        error
            ?
            <StaticPage
                extraClassName='public-card-page is-beta'
            >
                <div className='public-card-container'>
                    <div className='public-card-wrap'>
                        <div className='public-card-content'>
                            <PublicCardHeader />
                            <div className='error-content'>
                                <h1>{t( 'publicCard.SOMETHING_WENT_WRONG' )}</h1>
                                <p>{t( 'publicCard.UserWithSpecifiedIDNotFound' )}</p>
                            </div>
                            <PublicCardFooter />
                        </div>
                    </div>
                </div>
            </StaticPage>
            :
            <Center>
                <PublicLoader />
            </Center>
    )
}

export default CardByCode

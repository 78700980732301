

import './identity-field.scss'

const IdentityField = ({ label, value, onEdited, hasError = false }) => {

    const errorClass = hasError ? 'identity-field__error' : ''

    return (
        <div className='identity-field'>
            <span>{label}</span>
            <input
                onChange={( e ) => onEdited( e, label )}
                className={`identity-field__input ${errorClass}`}
                title={label}
                value={value}
            />
        </div>
    )

}

export default IdentityField

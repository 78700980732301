import './center.scss'

const Center = ({ children }) => {
    return (
        <div className="center-container">
            { children }
        </div>
    )
}

export default Center

/* eslint-disable no-case-declarations */
import { useEffect }             from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch }           from 'react-redux'

import TabeeAuth from '@tabee/frontend-auth'

import { request }     from 'tools'
import { authActions } from 'services'

const AuthCheck = () => {
    const
        { mode } = useParams(),
        history = useHistory(),
        dispatch = useDispatch(),

        parseSource = raw => {
            if ( !raw ) { return {} }

            const
                parsed = JSON.parse( atob( decodeURIComponent( raw )))

            return parsed
        }

    useEffect(() => {
        const
            query = new URLSearchParams( history.location.search ),
            returnURL = query.get( 'return_url' ),
            source = parseSource( query.get( 'source' ))

        switch ( mode ) {
            case 'sso':
                const
                    token = query.get( 'hash' ),
                    account = query.get( 'user' )

                request.storage.set( 'token', token )
                request.storage.set( 'user_id', account )
                request.storage.set( 'account', account )

                window.location.href = source.return_url || returnURL || '/'

                break

            case '2fa':
                // eslint-disable-next-line one-var
                const
                    email = query.get( 'email' ),
                    code = query.get( 'code' )

                TabeeAuth.signInCode({ email, code })
                    .then( event => {
                        dispatch( authActions.setAuthData( event ))
                        window.location.href = source.return_url || returnURL || '/'
                    })
                    .catch(() => {
                        history.push( '/' )
                    })

                break

            default:
                history.push( source.return_url || returnURL || '/' )
        }
    }, [])

    return <></>
}

export default AuthCheck

import { useEffect, useState }      from 'react'
import { useParams, useHistory }    from 'react-router-dom'
import classNames                   from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import { StaticPage, PublicLoader }                from 'components'
import { authActions, publicActions, userActions } from 'services'
import { users }                                   from 'tools'

import './../PublicCard/public-card.scss'

const VerifyLink = () => {
    const
        { id } = useParams(),
        verified = useSelector( state => state.public.validateLink ),
        user = useSelector( state => state.user.info ),
        token = useSelector( state => state.auth.info?.token ),
        history = useHistory(),
        dispatch = useDispatch(),
        query = new URLSearchParams( history.location.search )

    useEffect(() => {
        const
            result = {
                card:       id,
                owner:      query.get( 'owner' ),
                link:       query.get( 'link' ),
                code:       query.get( 'code' ),
                tags:       query.get( 'tags' ),
                tags_text:  query.get( 'tags_text' ),
                events:     query.get( 'events' ),
                comment:    query.get( 'comment' ),
                intro_text: query.get( 'intro_text' )
            }

        dispatch( publicActions.validateAndShare( result ))
    }, [])

    useEffect(() => {
        if ( verified ) {
            if ( verified?.data?.response ) {
                const
                    user_id = query.get( 'user_id' )

                dispatch( authActions.setAuthData({ status: true, account: user_id, token: verified.data.response?.token }))
            } else {
                history.push( `/p/${id}?use_card=${query.get( 'owner' )}` )
            }
        }
    }, [ verified ])

    useEffect(() => {
        if ( token ) {
            dispatch( userActions.getSelfUser())
        }
    }, [ token ])

    useEffect(() => {
        if ( user ) {
            history.push( `/p/${id}` )
        }
    })

    return (
        <StaticPage
            extraClassName={classNames(
                'public-card-page'
            )}
        >
            <PublicLoader
                visible={true}
            />
        </StaticPage>
    )
}

export default VerifyLink

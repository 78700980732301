/* IMPORT */
import { connect }                                 from 'react-redux'
import { authActions, userActions, publicActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    logged:    state.auth.logged,
    token:     state.auth.info.token,
    lastTry:   state.auth.lastTry,
    msg:       state.auth.msg,
    info:      state.user.info,
    resetSent: state.auth.resetSent
}),

      allActions = {
          ...authActions,
          ...userActions,
          ...publicActions
      }

export default connect( mapStateToProps, allActions )

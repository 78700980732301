import { request, users } from 'tools'
import * as api           from './api'

export const setAccount = request.updateAndSave(
    'storage.account',
    ( account, state ) => {
        const
            { info, companies } = state.user,
            current = account === info.id
                ? users.prepare( info )
                : users.prepare( companies.find( c => c.company.id === account ))

        return {
            ...state,
            user: {
                ...state.user,
                account,
                current
            }
        }
    }
)

export const getUser = request.asyncUpdate(
    'storage.user.get',
    id => api.getUser( id ),
    ( event, state ) => ({
        ...state,
        user: {
            ...state.user,
            info:    event.data,
            account: state.user.account || event.data.id,
            current: state.user.account === event.data.id
                ? users.prepare( event.data )
                : state.user.current
        }
    })
)

export const getSelfUser = request.asyncUpdate(
    'storage.user.get.self',
    () => api.getSelfUser(),
    ( event, state ) => {

        if ( event.status >= 300 ) {

            request.storage.remove( 'token' )
            request.storage.remove( 'user_id' )
            request.storage.remove( 'account' )

            //TabeeAuth.SSOSignIn( `${window.location.host}/auth/sso`, window.location.pathname )

            return {
                ...state,
                user: null
            }
        }

        return {
            ...state,
            user: {
                ...state.user,
                info:    event.data,
                account: state.user.account || event.data.id,
                current: state.user.account === event.data.id
                    ? users.prepare( event.data )
                    : state.user.current
            }
        }
    }
)

export const getUserCards = request.asyncUpdate(
    'storage.user.cards.get',
    () => api.getCards(),
    ( event, state ) => ({
        ...state,
        user: {
            ...state.user,
            cards: event.data.results
        }
    })
)

export const getUserContacts = request.asyncUpdate(
    'storage.user.contacts.get',
    () => api.getContacts(),
    ( event, state ) => ({
        ...state,
        user: {
            ...state.user,
            contacts: event.data
        }
    })
)

export const getUserTags = request.asyncUpdate(
    'storage.user.tags.get',
    () => api.getTags(),
    ( event, state ) => ({
        ...state,
        user: {
            ...state.user,
            tags: event.data
        }
    })
)

export const getCompanies = request.asyncUpdate(
    'storage.user.companies.get',
    () => api.getRoles(),
    ( event, state ) => {

        const
            companies = event.data
                .filter(
                    ( role, index ) => event.data.findIndex( r => r.company.id === role.company.id ) === index
                )
                .map( data => {
                    const
                        res = { ...data },
                        siblings = event.data.filter( r => r.company.id === data.company.id )

                    Object.keys( res.role ).forEach( key => {
                        if ( key.substr( 0, 3 ) === 'can' ) {
                            res.role[ key ] = siblings.reduce(( value, current ) => value || current.role[ key ], res.role[ key ])
                        }
                    })

                    return res
                })

        return {
            ...state,
            user: {
                ...state.user,
                companies,
                current: state.user.account !== state.user.info.id
                    ? users.prepare( companies.find( c => c.company.id === state.user.account ))
                    : state.user.current
            }
        }
    }
)

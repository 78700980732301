/* eslint-disable no-case-declarations */
import React          from 'react'
import { withRouter } from 'react-router'
import { Button }     from 'antd'
import classNames     from 'classnames'
import Adjust         from '@adjustcom/adjust-web-sdk'
import imageClipper   from 'image-clipper'

import TabeeAuth from '@tabee/frontend-auth'

import { StaticPage, Spinner, PublicCardHeader, PublicCardFooter, PublicLoader } from 'components'
import { generate, hash, t, adjust, ui, find }                                   from 'tools'
import config                                                                    from 'config'

import AuthForm    from './components/AuthForm'
import CardContent from './components/CardContent'
import Contacts    from './components/Contacts'
import LiteForm    from './components/LiteForm'
//import LoginForm        from './components/LoginForm/LoginForm'
import MailForm      from './components/MailForm'
import Onboarding    from './components/Onboarding'
import TabeeAd       from './components/TabeeAd'
import IdentityField from './components/IdentityField/IdentityField'

import AppleWallet  from './applewallet.svg'
import GoogleWallet from './googlewallet.svg'

import connector from './connector'
import './public-card.scss'

class PublicCard extends React.Component {

    uploadRef = React.createRef()
    singleTimer = null

    constructor ( props ) {
        super( props )

        this.state = {
            name:           null,
            email:          '',
            personal:       false,
            formOpen:       false,
            userCard:       null,
            sharing:        false,
            loading:        false,
            addOnload:      null,
            avatarUpload:   false,
            avatarCallback: null,
            singleRedirect: false,
            singleTime:     0,
            identityFields: {
                appv:           '1.1',
                company_design: 'tabee.bamboo',
                platform:       '3',
                project:        '73e9b050-3259-11ec-8038-6a13bef2453b',
                type:           'id-card',
            },
            identityErrors:    {},
            onSaveCallback:    null,
            isOwnMigrated:     hash.extract( 'migrated' ),
            canShare:          'loading',
            downloaded:        false,
            searchingEvents:   false,
            savedLocation:     null,
            forceNearestEvent: false,
            noEvents:          false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { match, account, user } = this.props,
            preemail = window.location.search?.match(
                /(\?|&)?mail=([^@]+@[^.]+.[a-z]+)/,
            )?.[ 2 ],
            fieldsFromURL = this.getIdentityFieldsFromURL( window.location.search )

        if ( this.checkReturnURL()) {
            return
        }

        if ( !match.params.id ) {
            if ( window.location.pathname.indexOf( '/u/' ) < 0 || !account ) {
                window.location = '/'
                return
            }
        }

        document.documentElement.classList.add( 'public-card-theme' )

        if (
            window.location.pathname.indexOf( '/u/' ) === 0 &&
            !!account &&
            user?.cards?.length > 0
        ) {
            window.location.href = `/u/${user.cards[ 0 ].id}`
        } else {
            if ( match.params.id?.toLowerCase() !== 'new' ) {
                this.props.getProjectConfig( t( 'global.projectID' ))
                this.props.getPublicCard( match.params.id )
                !!user && this.props.getCustomDesigns()
            }
        }

        preemail && this.set.email( preemail )

        // ================== TASK3 ==================
        this.setIdentityFieldsFromObject( fieldsFromURL )
        // ================== TASK3 ==================
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            {
                user,
                data,
                active,
                match,
                userCards,
                verify,
                search,
                migrated,
                needSave,
                activated,
                precreated,
                reloadContacts,
                shared,
                location,
                account,
                cardSaved,
                linkExist,
                userInfo,
                justCreated,
                nearestEvents
            } = this.props,
            { addOnload, singleRedirect, userCard, onSaveCallback, downloaded, searchingEvents, noEvents } = this.state,
            isPromo = (
                location.pathname.indexOf( '/p/' ) === 0 ||
                location.pathname.indexOf( '/digital/' ) === 0
            ),
            isMine = !!userCard && userCard.id === data?.id,
            hasDownload = ( new URLSearchParams( window.location.search )).has( 'download' ),
            downloadWallet = !downloaded && hasDownload

        if ( shared !== prevProps.shared && shared ) {
            setTimeout(() => {
                const
                    $info = document
                        .querySelector( '.public-card-info' )

                ;( $info ) && ( $info.scrollIntoView({ behavior: 'smooth', block: 'nearest' }))
            }, 300 )

            this.props.getCustomDesigns()
        }

        if ( reloadContacts !== prevProps.reloadContacts ) {
            this.props.getUserContacts()
            activated
                ? this.props.getActivated( match.params.id )
                : this.props.getPublicCard( match.params.id )
        }

        if ( cardSaved !== prevProps.cardSaved && cardSaved ) {
            if ( onSaveCallback ) {
                onSaveCallback()
                this.set.onSaveCallback( null )
            }
        }

        if ( needSave !== prevProps.needSave && needSave ) {
            activated
                ? this.props.updateCardReload({
                    id:              activated.id,
                    shared_contacts: activated.shared_contacts.map(( c ) => c.id ),
                })
                : this.props.updateCardReload({
                    id:              data.id,
                    shared_contacts: data.shared_contacts.map(( c ) => c.id ),
                })
        }

        if ( precreated !== prevProps.precreated && precreated ) {
            window.location.href = isPromo
                ? `/p/${precreated.card.id}`
                : `/u/${precreated.card.id}`
            return
        }

        if ((( userCards !== prevProps.userCards ) || ( !userCard && data !== prevProps.data )) && userCards && data ) {
            const
                urlParams = new URLSearchParams( window.location.search ),
                cardToUse = urlParams.get( 'use_card' ),
                filtered = userCards.filter(
                    ( card ) =>
                        !card?.is_archive &&
                        !card?.is_deleted &&
                        !card?.parent &&
                        !card?.migrated_to,
                ),
                useCard = userCards.find( c => c.id === decodeURIComponent( cardToUse )),
                found = filtered.find( c => c.id === data.id )

            ;( useCard || found )
                ? this.setState({ userCard: useCard || found })
                : filtered[ 0 ] && this.setState({ userCard: filtered[ 0 ] })
        }

        if ((( !!data && user !== prevProps.user ) || ( !!user && data !== prevProps.data )) && user && user.id && !data.is_lite ) {
            this.set.formOpen( true )

            Adjust.addGlobalCallbackParameters([ { key: 'userID', value: user.id } ])
        }

        if ( data !== prevProps.data ) {
            if ( data.migrated_to && data.id !== data.migrated_to ) {
                window.location.href = isPromo
                    ? `/p/${data.migrated_to}`
                    : `/u/${data.migrated_to}`
            }

            if ( isMine ) {
                this.set.state({
                    formOpen:     false,
                    avatarUpload: false,
                })

                this.checkAddContact()

                if ( data.single_contact !== prevProps.data?.single_contact ) {
                    if ( !data.single_contact && !!singleRedirect ) {
                        this.set.singleRedirect( false )
                    } else if ( !!data.single_contact && !singleRedirect ) {
                        this.set.singleRedirect( true )
                    }
                }
            } else {
                if ( data?.single_contact && !singleRedirect && !account ) {
                    this.set.state({
                        singleRedirect: true,
                        singleTime:     5,
                    })
                    this.singleTimer = setInterval( this.singleCountdown, 1000 )
                }
            }
        }

        if ( userCard !== prevState.userCard ) {
            if ( userCard?.related_event ) {
                this.props.loadCurrentUserCard( userCard.id )
            }

            if ( data?.single_contact && !singleRedirect && userCard ) {
                if ( isMine ) {
                    this.set.singleRedirect( true )
                } else {
                    this.set.state({
                        singleRedirect: true,
                        singleTime:     5,
                    })
                    this.singleTimer = setInterval( this.singleCountdown, 1000 )
                }
            }

            this.checkAddContact()
            !!user && !!account && isMine && this.props.getCustomDesigns()
            this.props.checkDuplicate({ owner_card: userCard.id, recipient: match.params.id })
        }

        if ( linkExist !== prevProps.linkExist ) {
            if ( linkExist === void 0 ) {
                this.set.canShare( 'loading' )
            } else {
                this.set.canShare( !linkExist )
            }
        }

        if ( active !== prevProps.active ) {
            if ( active?.card ) {
                this.props.updateCard({
                    id:              active.card.id,
                    shared_contacts: [
                        active.card.email?.id,
                        active.card.phone?.id,
                    ].filter(( v ) => !!v ),
                })
            }
            this.props.getActivated( match.params.id )
            !!user && this.props.getCustomDesigns()
        }

        if ( activated !== prevProps.activated && activated ) {
            if ( addOnload ) {
                this.props.createContact({
                    type:    'email',
                    contact: addOnload,
                    subtype: '',
                })

                this.set.addOnload( null )
            }

            if ( activated.single_contact !== prevProps.activated?.single_contact ) {
                if ( !activated.single_contact && !!singleRedirect ) {
                    this.set.singleRedirect( false )
                } else if ( !!activated.single_contact && !singleRedirect ) {
                    this.set.singleRedirect( true )
                }
            }

            this.set.avatarUpload( false )
        }

        if (
            ( shared !== prevProps.shared && shared ) ||
            ( activated !== prevProps.activated && activated )
        ) {
            const addSocial = localStorage.getItem( 'tabee-social-contact' )

            if ( addSocial ) {
                this.props.createContact({
                    type:    'other',
                    contact: addSocial,
                })
                localStorage.removeItem( 'tabee-social-contact' )
            }
        }

        if ( verify !== prevProps.verify && verify ) {
            this.set.formOpen( true )
        }

        if ( search !== prevProps.search && search ) {
            this.set.formOpen( true )
        }

        if ( migrated !== prevProps.migrated && migrated ) {
            window.location.href = isPromo
                ? `/p/${migrated.card.id}?migrated`
                : `/u/${migrated.card.id}?migrated`
        }

        if ( search !== prevProps.search && search && search.length > 0 && !data?.is_lite ) {
            if (
                search[ 0 ].owner_user &&
                search[ 0 ].owner_user.id !== data?.owner_user?.id
            ) {
                adjust.track( 'form_existing' )

                find.geolocation()
                    .then( location => {
                        if ( location ) {
                            this.set.savedLocation( location )
                            this.props.getNearestEvents( location )
                            this.props.sharePublicContacts({
                                owner:     search[ 0 ].id,
                                recipient: match.params.id,
                                location
                            })
                        } else {
                            this.props.sharePublicContacts({
                                owner:     search[ 0 ].id,
                                recipient: match.params.id
                            })
                        }
                    })

                this.setState({ sharing: true })
            } else {
                adjust.track( 'form_self' )
                window.location.search =
                    window.location.search[ 0 ] === '?'
                        ? `${window.location.search}&own_card`
                        : '?own_card'
            }
        }

        if (
            userInfo?.token &&
            data && !data.is_lite &&
            downloadWallet && !downloaded &&
            !(
                !active &&
                (
                    !data ||
                    ( account && !user ) ||
                    ( !!user?.id && !userCard && user?.cards?.length > 0 )
                )
            )
        ) {
            const
                downloadType = ( new URLSearchParams( window.location.search )).get( 'download' )

            switch ( downloadType ) {
                case 'apple_wallet':
                    const
                        link = document.createElement( 'a' )

                    link.href = `${config.api.url}/pb/${data.id}`
                    //link.addEventListener( 'click', () => adjust.track( 'wallet_after_share' ))

                    link.click()

                    this.set.downloaded( true )
                    window.history.replaceState({}, '', window.location.origin + window.location.pathname )
                    break

                case 'google_wallet':
                    const
                        glink = document.createElement( 'a' )

                    glink.href = `${config.api.url}/gp_wallet/${data.id}?create_news=false`
                    glink.click()
                    break

                default:
                    console.error( 'Unknown download:', downloadType )
            }
        }

        if ( !searchingEvents && !nearestEvents && justCreated != prevState.justCreated && justCreated ) {
            if ( !noEvents ) {
                this.set.searchingEvents( true )

                find.geolocation().then( location => {
                    location && this.props.getNearestEvents( location )
                    this.set.searchingEvents( false )
                    this.set.noEvents( true )
                })
            }
        }

        if ( nearestEvents !== prevProps.nearestEvents ) {
            if ( nearestEvents?.length > 0 && shared ) {
                this.set.forceNearestEvent( true )
            }
        }

        if ( shared !== prevProps.shared && shared && nearestEvents?.length > 0 ) {
            this.set.forceNearestEvent( true )
        }
    }

    checkReturnURL = () => {
        const
            urlParams = new URLSearchParams( window.location.search ),
            returnURL = urlParams.get( 'return_url' ),
            useCard = urlParams.get( 'use_card' )

        if ( returnURL ) {
            window.location = `${decodeURIComponent( returnURL )}${ useCard ? `?use_card=${useCard}` : ''}`
            return true
        }

        return false
    }

    checkAddContact = () => {
        const
            { data } = this.props,
            { userCard } = this.state,
            isMine = !!userCard && userCard.id === data?.id,
            urlParams = new URLSearchParams( window.location.search ),
            addContact = urlParams.get( 'addcontact' ),
            contactType = urlParams.get( 'contact_type' )

        if ( isMine && addContact ) {
            this.props.createContact({
                type:    contactType || 'other',
                contact: addContact
            })

            urlParams.delete( 'addcontact' )
            urlParams.delete( 'contact_type' )
            history.replaceState({}, null, `${window.location.pathname}${ Array.from( urlParams.keys()).length > 0 ? `?${ urlParams.toString() }` : ''}` )
        }
    }

    getIdentityFieldsFromURL = ( queryString ) => {
        const params = new URLSearchParams( queryString ),

              fields = {
                  appv:           undefined,
                  company_design: undefined,
                  platform:       undefined,
                  project:        undefined,
                  type:           undefined,
              }

        Object.keys( fields ).forEach(
            ( key ) => ( fields[ key ] = params.get( key ) || this.state.identityFields[ key ]),
        )

        return fields
    }

    setIdentityFieldsFromObject = ( newFields ) => {
        const { identityFields, identityErrors } = this.state,
              errors = {}
        for ( const [ key, value ] of Object.entries( newFields )) {
            errors[ key ] = !value
        }

        this.setState({
            identityFields: {
                ...identityFields,
                ...newFields,
            },

            identityErrors: {
                ...identityErrors,
                ...errors,
            },
        })
    }

    singleCountdown = () => {
        const { data } = this.props,
              { singleRedirect, singleTime } = this.state,
              time = singleTime - 1

        if ( singleRedirect ) {
            this.set.singleTime( time )

            if ( time <= 0 ) {
                clearInterval( this.singleTimer )
                window.location.href = data.single_contact.contact?.substr( 0, 4 ).toLowerCase() !== 'http' ? `https://${data.single_contact.contact}` : data.single_contact.contact
            }
        }
    }

    stopRedirect = () => {
        clearInterval( this.singleTimer )

        this.set.state({
            singleTime:     0,
            singleRedirect: false,
        })
    }

    getErrorContent = () => {
        const { error, data } = this.props,
              status = data?.error ? data.status : error.status

        switch ( status ) {
            case 404:
                return <p>{t( 'publicCard.UserWithSpecifiedIDNotFound' )}</p>
            default:
                return <p>{t( 'publicCard.TryToReloadAPageOrComeBackLater' )}</p>
        }
    }

    setMail = ( email, isPublic ) => {
        const
            { verify, search, data } = this.props

        this.set.state({
            email:    email ? email.trim() : '',
            personal: isPublic,
            formOpen: !!verify && !!search && !data?.is_lite,
        })
    }

    onShare = () => {
        const
            { data } = this.props,
            { userCard, canShare } = this.state

        if ( !canShare || canShare === 'loading' ) { return }

        this.setState({ sharing: true })

        find.geolocation()
            .then( location => {
                if ( location ) {
                    this.set.savedLocation( location )
                    this.props.getNearestEvents( location )

                    this.props.shareContacts({
                        owner_card:  userCard.id,
                        recipient:   data.id,
                        is_send:     true,
                        is_hold:     false,
                        source_type: 'v2',
                        //location
                    })
                } else {
                    this.props.shareContacts({
                        owner_card:  userCard.id,
                        recipient:   data.id,
                        is_send:     true,
                        is_hold:     false,
                        source_type: 'v2',
                    })
                }

                adjust.track( 'form_completed' )
            })
    }

    createNewCard = () => {
        const { identityFields } = this.state
        this.props.precreateCard({ ...identityFields })
        this.set.loading( true )
    }

    onMigrate = data => {
        this.props.migrateCard( data )
    }

    checkAndActivate = ({ personal, ...data }) => {
        if ( personal ) {
            this.set.addOnload( personal )
        }

        this.props.activateUser({
            ...data,
            phone: data.phone?.replace( /[- ]+/g, '' )
        })
    }

    uploadAvatar = ( cb ) => {
        const { user } = this.props

        !!user && this.uploadRef.current.click()
        this.set.avatarCallback( cb )
    }

    resizeFile = ( file ) => {
        return new Promise(( resolve ) => {
            const
                img = new Image

            img.onload = () => {
                const
                    ow = img.width,
                    oh = img.height,
                    min = Math.min( ow, oh ),
                    cx = ow / 2,
                    cy = oh / 2,
                    half = min / 2,
                    x = cx - half,
                    y = cy - half,
                    w = min,
                    h = min,
                    rsize = Math.min( 300, min )

                imageClipper( img, function CropImage () {
                    this.crop( x, y, w, h )
                        .resize( rsize, rsize )
                        .quality( 90 )
                        .toDataURL( raw => {
                            const
                                cropped = new Image

                            cropped.src = raw

                            fetch( cropped.src )
                                .then( res => res.blob())
                                .then( blob => {
                                    resolve( new File([ blob ], file.name, { type: 'image/png' }))
                                })
                        })
                })
            }

            img.src = URL.createObjectURL( file )
        })
    }

    processUploadAvatar = async ( e ) => {
        const
            { user, shared } = this.props,
            { avatarCallback } = this.state

        try {

            !shared && this.set.avatarUpload( true )

            const
                file = e.target.files[ 0 ],
                image = await this.resizeFile( file )

            if ( image ) {
                const
                    formData = new FormData()

                formData.append( 'avatar', image )
                formData.append( 'id', user.id )

                this.props.updateAvatar( formData )

                if ( avatarCallback ) {
                    avatarCallback()
                    this.set.avatarCallback( null )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    setDesign = ( design, callback ) => {
        const { data, shared } = this.props,
              { userCard } = this.state

        this.props.updateCard({
            id:             shared ? userCard.id : data.id,
            company_design: design.id === 'default' ? null : design.id,
            is_branded:     design.id === 'default' ? false : true,
        })

        this.set.onSaveCallback( callback )
    }

    onEdited = ( e, field ) => {
        const { identityFields, identityErrors } = this.state

        this.setState({
            identityFields: {
                ...identityFields,
                [ field ]: e.target.value,
            },
            identityErrors: {
                ...identityErrors,
                [ field ]: e.target.value === '',
            },
        })
    }

    paymentOnboarding = ( params ) => {
        const
            status = params.get( 'payment-status' )

        switch ( status ) {

            case 'success':
                return [ {
                    content: {
                        header:  'payment_status_header_success',
                        sublogo: '',
                        text:    'payment_status_body_success'
                    },
                    hideif: { loggedin: false },
                    images: {
                        ios:     '',
                        android: '',
                        default: 'https://tabee.app/ob_/payment-status-img_success.png'
                    },
                    order:          90,
                    primary_button: {
                        action: '#next',
                        text:   'payment_status_button_success'
                    },
                    secondary_button: null,
                    translate_prefix: 'ob_',
                    type:             'default'
                } ]

            case 'cancelled':
                return [ {
                    content: {
                        header:  'payment_status_header_cancelled',
                        sublogo: '',
                        text:    'payment_status_body_cancelled'
                    },
                    hideif: { loggedin: false },
                    images: {
                        ios:     '',
                        android: '',
                        default: 'https://tabee.app/ob_/payment-status-img_cancelled.png'
                    },
                    order:          90,
                    primary_button: {
                        action: '#next',
                        text:   'payment_status_button_cancelled'
                    },
                    secondary_button: null,
                    translate_prefix: 'ob_',
                    type:             'default'
                } ]

            default:
                return null

        }
    }

    getOnlyNearestEvent = onboarding => onboarding.filter( step => step.type === 'map' )

    mergeOnboarding = ( args ) => {
        const
            params = new URLSearchParams( window.location.search ),
            isPayment = params.has( 'payment-status' )

        if ( isPayment ) {
            const
                payment = this.paymentOnboarding( params )

            if ( payment ) {
                return payment
            }
        }

        const
            res = []

        args.filter( arr => !!arr && Array.isArray( arr )).forEach( arr => {
            arr.forEach( insertItem => {
                const
                    foundIndex = res.findIndex( item => item.order === insertItem.order )

                if ( foundIndex < 0 ) {
                    res.push( insertItem )
                } else {
                    res[ foundIndex ] = insertItem
                }
            })
        })

        return res
    }

    getBase64 = ( str ) => {
        if ( !str ) {
            return ''
        }

        if ( Array.isArray( str )) {
            try {
                return str.map( s => btoa( s )).join( ' ' )
            } catch ( e ) {
                return ''
            }
        }

        try {
            return btoa( str )
        } catch ( e ) {
            return ''
        }
    }

    render () {
        const
            {
                data,
                match,
                verify,
                user,
                steps,
                search,
                active,
                activated,
                shared,
                types,
                contacts,
                location,
                account,
                socials,
                onboarding,
                justCreated,
                customDesigns,
                userInfo,
                currentCard,
                validateLink,
                nearestEvents,
                sharedLinks,
                sharedData
            } = this.props,
            {
                email,
                personal,
                formOpen,
                userCard,
                sharing,
                loading,
                avatarUpload,
                singleRedirect,
                identityFields,
                identityErrors,
                isOwnMigrated,
                canShare,
                downloaded,
                searchingEvents,
                forceNearestEvent
            } = this.state,
            name = `${data?.first_name} ${data?.last_name}`,

            isBeta = true,
            isMine = !!userCard && userCard.id === data?.id,
            isFound = location.pathname.indexOf( '/found/' ) === 0 && !isMine,
            isPromo = (
                location.pathname.indexOf( '/p/' ) === 0 ||
                location.pathname.indexOf( '/digital/' ) === 0
            ),
            isDigital = location.pathname.indexOf( '/digital/' ) === 0,

            selfSharing = (
                hash.extract( 'own_card' ) ||
                ( data?.owner_user?.id === user?.id && !isMine )
            ),
            os = ui.getCurrentOs(),
            showWallet = (
                justCreated &&
                ( steps?.data?.exchange?.owner_card || steps?.data?.exchange?.card?.id ) &&
                ( os === 'ios' || os === 'android' )
            ),
            error = this.props.error || data?.error || shared?.error,
            showOnboard =
                !!data &&
                (
                    forceNearestEvent ||
                    (
                        ( customDesigns || !account ) &&
                        ( nearestEvents?.length > 0 || !searchingEvents ) &&
                        (
                            ( shared && userCard && justCreated ) ||
                            isOwnMigrated ||
                            window.location.search.indexOf( '__show_onboard__' ) > -1 ||
                            ( new URLSearchParams( window.location.search )).has( 'payment-status' ) ||
                            ( isMine && !!userCard && !!user?.id && !isFound && data?.is_lite )
                        )
                    )
                ),
            downloadWallet = ( new URLSearchParams( window.location.search )).has( 'download' ),

            visibleContacts = activated && userCard && userCard.id === data?.id
                ? activated?.shared_contacts
                : data?.shared_contacts,

            userEmail = ( Array.isArray( contacts ) ? contacts : []).find(
                ( c ) => c.type === 'email',
            )?.contact,

            elements = Object.keys( identityFields ).map(( field, idx ) => {
                return (
                    <li key={`field+${idx}`}>
                        <IdentityField
                            onEdited={this.onEdited}
                            label={field}
                            value={identityFields[ field ]}
                            hasError={identityErrors[ field ]}
                        />
                    </li>
                )
            }),
            hasDownload = ( new URLSearchParams( window.location.search )).has( 'download' ),
            downloadType = ( new URLSearchParams( window.location.search )).get( 'download' )

        if (
            match.params?.id?.toLowerCase() === 'new' &&
            window.location.hostname !== 'go.tabee.mobi' &&
            window.location.hostname !== 'id.tabee.mobi'
        ) {
            return (
                <StaticPage
                    extraClassName={classNames(
                        'public-card-page',
                        isBeta && 'is-beta',
                        document.body.className.indexOf( 'flow-lock' ) > -1 && 'flow-lock',
                    )}
                >
                    <div className='public-card-container'>
                        <div className='public-card-wrap'>
                            <div
                                className={classNames(
                                    'public-card-content',
                                    ( formOpen || ( user && user.id )) &&
                                        !data?.is_lite &&
                                        'form-open',
                                    user && 'with-user-card',
                                    user && userCard?.id === data?.id && 'is-my-card',
                                    ( sharing || shared ) && 'is-sharing',
                                )}
                            >
                                <PublicCardHeader />
                                <div className='big-create-button center-container'>
                                    <ul className='big-create-button__identity-fields'>
                                        {elements}
                                    </ul>

                                    <Button
                                        type='primary'
                                        onClick={this.createNewCard}
                                        disabled={Object.values( identityErrors ).includes(
                                            true,
                                        )}
                                    >
                                        {loading ? (
                                            <Spinner size='small' />
                                        ) : (
                                            t( 'publicCard.CREATE_NEW_CARD' )
                                        )}
                                    </Button>
                                </div>
                            </div>
                            <TabeeAd data={data} id={data?.id} isLite={data?.is_lite} />
                            <PublicCardFooter />
                        </div>
                    </div>
                </StaticPage>
            )
        }

        if ( error ) {
            console.error( error )
            console.error( data )
        }

        return (
            <StaticPage
                extraClassName={classNames(
                    'public-card-page',
                    isBeta && 'is-beta',
                    singleRedirect && 'with-redirect',
                )}
            >
                {
                    ( userInfo?.token && userInfo?.user?.id ) && (
                        <iframe src={`${config.sso.url}/pixel/${userInfo.user.id}/${userInfo.token}`} style={{ position: 'absolute', left: -9999, top: -9999, opacity: 0, width: 1, height: 1 }} />
                    )
                }
                <div className='public-card-container'>
                    <div className='public-card-wrap'>
                        <div
                            className={classNames(
                                'public-card-content',
                                ( formOpen || ( user && user.id )) &&
                                    !data?.is_lite &&
                                    'form-open',
                                user && 'with-user-card',
                                (( user && userCard?.id === data?.id ) || isOwnMigrated ) &&
                                    'is-my-card',
                                ( sharing || shared ) && 'is-sharing',
                                'hui'
                            )}
                        >
                            { data?.is_lite && <PublicCardHeader />}
                            {data && ( !data.migrated_to || data.id === data.migrated_to ) && !data.error ? (
                                data.is_lite ? (
                                    <>
                                        <LiteForm
                                            key='form'
                                            id={match.params.id}
                                            done={!!active}
                                            data={activated}
                                            search={search}
                                            company={verify}
                                            design={activated?.company_design}
                                            email={email}
                                            socials={socials}
                                            loading={avatarUpload}
                                            canUpload={isMine}
                                            isDigital={isDigital}
                                            isMine={isMine || isOwnMigrated}
                                            onAvatar={isMine ? this.uploadAvatar : null}
                                            searchCard={this.props.searchCard}
                                            clearErrors={this.props.clearStepsErrors}
                                            clearCompany={this.props.flushVerifyDomain}
                                            getCompany={this.props.verifyDomain}
                                            onCreate={this.checkAndActivate}
                                            onMigrate={this.onMigrate}
                                        />
                                        <input
                                            key='avatarUpload'
                                            ref={this.uploadRef}
                                            type='file'
                                            accept='.png,.jpg,.jpeg'
                                            style={{
                                                opacity: 0,
                                                height:  '0px',
                                                width:   '0px',
                                            }}
                                            onChange={this.processUploadAvatar}
                                        />
                                    </>
                                ) : (
                                    [
                                        !isFound && (
                                            <React.Fragment key='info'>
                                                <CardContent
                                                    key='info'
                                                    isMine={isMine || isOwnMigrated}
                                                    data={data}
                                                    event={currentCard?.related_event || data.related_event}
                                                    name={name}
                                                    id={data?.id}
                                                    hideButton={shared && validateLink}
                                                    className={
                                                        userCard || !user?.id
                                                            ? ''
                                                            : 'is-loading'
                                                    }
                                                    loading={avatarUpload}
                                                    canUpload={isMine}
                                                    designs={customDesigns}
                                                    onAvatar={
                                                        isMine ? this.uploadAvatar : null
                                                    }
                                                    onUpdate={
                                                        isMine
                                                            ? this.props.updateCardReload
                                                            : null
                                                    }
                                                />
                                                {( isMine || ( shared && justCreated )) && (
                                                    <input
                                                        key='avatarUpload'
                                                        ref={this.uploadRef}
                                                        type='file'
                                                        accept='.png,.jpg,.jpeg'
                                                        style={{
                                                            opacity: 0,
                                                            height:  '0px',
                                                            width:   '0px',
                                                        }}
                                                        onChange={
                                                            this.processUploadAvatar
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        ),
                                        data &&
                                            isBeta &&
                                            !data.is_lite &&
                                            !isOwnMigrated &&
                                            ( shared || selfSharing ? (
                                                <div
                                                    className={classNames(
                                                        'public-card-form success-send',
                                                        ( showWallet || ( shared && validateLink )) && 'with-wallet-btn',
                                                    )}
                                                    key='form'
                                                >
                                                    <h2 className='public-card-notice'>
                                                        {
                                                            selfSharing
                                                                ? t( 'publicCard.NO_SHARING_SELF' )
                                                                : (
                                                                    validateLink
                                                                        ? t( 'publicCard.SHARING_CONFIRM_SUCCESS' )
                                                                        : t( 'publicCard.THANKS_FOR_SHARING' )
                                                                )
                                                        }
                                                    </h2>
                                                    {
                                                        ( shared && validateLink ) && (
                                                            user
                                                                ? (
                                                                    <div className='wallet-container'>
                                                                        <Button type="primary" onClick={
                                                                            () => {
                                                                                this.props.flushValidateLink()
                                                                            }}
                                                                        >
                                                                            {t( 'publicCard.AFTER_SHARE_CONTINUE' )}
                                                                        </Button>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div className='wallet-container'>
                                                                        <p className="offset">
                                                                            {
                                                                                t( 'publicCard.SHARING_CAN_LOGIN' )
                                                                            }
                                                                        </p>
                                                                        <Button type="primary" onClick={
                                                                            () => {
                                                                                TabeeAuth.SSOSignIn( '/', window.location.pathname )
                                                                            }}
                                                                        >
                                                                            {t( 'auth.loginSignIn' )}
                                                                        </Button>
                                                                    </div>
                                                                )
                                                        )
                                                    }
                                                    {
                                                        showWallet && (
                                                            <div className='wallet-container'>
                                                                <p>
                                                                    {
                                                                        t( 'publicCard.DOWNLOAD_PREVIOUSLY_SAVED_CARD' )
                                                                    }
                                                                </p>
                                                                <a
                                                                    href={
                                                                        os == 'ios'
                                                                            ? `${config.api.url}/pb/${steps.data.exchange.owner_card || steps.data.exchange.card?.id}`
                                                                            : `${config.api.url}/gp_wallet/${steps.data.exchange.owner_card || steps.data.exchange.card?.id}?create_news=false`
                                                                    }
                                                                    target={ os == 'ios' ? '_self' : '_blank' }
                                                                    rel='noreferrer'
                                                                    onClick={() => adjust.track( 'wallet_after_share' )}
                                                                >
                                                                    {
                                                                        os === 'ios'
                                                                            ? (
                                                                                <img
                                                                                    src={AppleWallet}
                                                                                    alt='Add to Apple Wallet'
                                                                                />
                                                                            )
                                                                            : (
                                                                                <img
                                                                                    src={GoogleWallet}
                                                                                    alt='Add to Google Wallet'
                                                                                />
                                                                            )
                                                                    }
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : !!user?.id ||
                                              ( isOwnMigrated || ( email && search && search.length === 0 ))
                                                ? (
                                                    user && user.id
                                                        ? (
                                                            userCard
                                                                ? (
                                                                    userCard.id !== data.id && !isFound
                                                                        ? (
                                                                            <CardContent
                                                                                withShare
                                                                                key='form'
                                                                                data={userCard}
                                                                                event={currentCard?.related_event || data.related_event}
                                                                                name={`${userCard?.first_name} ${userCard?.last_name}`}
                                                                                id={userCard.id}
                                                                                working={sharing}
                                                                                canShare={canShare}
                                                                                onShare={this.onShare}
                                                                            />
                                                                        )
                                                                        : null
                                                                )
                                                                : (
                                                                    <Spinner key='form' />
                                                                )
                                                        )
                                                        : (
                                                            <AuthForm
                                                                key='form'
                                                                data={data}
                                                                company={verify}
                                                                user={user}
                                                                steps={steps}
                                                                target_id={match.params.id}
                                                                event_id={hash.extract( 'event' )}
                                                                email={email}
                                                                personal={personal}
                                                                socials={socials}
                                                                clearErrors={
                                                                    this.props.clearStepsErrors
                                                                }
                                                                clearCompany={
                                                                    this.props.flushVerifyDomain
                                                                }
                                                                onStep={this.props.stepHandlers}
                                                                onRegister={
                                                                    this.props.oneRegForAll
                                                                }
                                                                getCompany={
                                                                    this.props.verifyDomain
                                                                }
                                                            />
                                                        )
                                                ) : (
                                                    <MailForm
                                                        key='form'
                                                        user={user}
                                                        company={verify}
                                                        card={search}
                                                        event={currentCard?.related_event || data.related_event}
                                                        email={email}
                                                        clearErrors={
                                                            this.props.clearStepsErrors
                                                        }
                                                        clearCard={this.props.flushSearchCard}
                                                        searchCard={this.props.searchCard}
                                                        getCompany={this.props.verifyDomain}
                                                        setMail={this.setMail}
                                                    />
                                                )),
                                    ]
                                )
                            ) : error ? (
                                <div className='error-content'>
                                    <h1>{t( 'publicCard.SOMETHING_WENT_WRONG' )}</h1>
                                    {this.getErrorContent()}
                                </div>
                            ) : (
                                <div className='public-card-center'>
                                    <Spinner />
                                </div>
                            )}
                        </div>
                        {(( data && !data.is_lite ) || activated ) && !isFound && ( visibleContacts?.length || isMine ) && (
                            <Contacts
                                types={types}
                                editable={isMine}
                                data={
                                    activated && userCard && userCard.id === data?.id
                                        ? activated
                                        : data
                                }
                                id={
                                    activated && userCard && userCard.id === data?.id
                                        ? activated?.id
                                        : data?.id
                                }
                                contacts={contacts}
                                shared={ visibleContacts }
                                updateCard={this.props.updateCardReload}
                                updateContact={this.props.updateContact}
                                createContact={this.props.createContact}
                                deleteContact={this.props.removeContact}
                            />
                        )}
                        {activated && (
                            <TabeeAd
                                data={data}
                                id={data?.id}
                                company={verify}
                                isLite={data?.is_lite || !!verify || search?.length > 0}
                                isMine={isMine || isOwnMigrated}
                            />
                        )}
                        <PublicCardFooter />
                    </div>
                </div>
                {
                    ( onboarding ) && (
                        <Onboarding
                            visible = { showOnboard || forceNearestEvent }
                            config = {
                                this.mergeOnboarding([
                                    forceNearestEvent
                                        ? this.getOnlyNearestEvent( onboarding )
                                        : onboarding,
                                    data?.company_design?.custom_json
                                        ? JSON.parse( data?.company_design?.custom_json )?.onboarding?.custom
                                        : null
                                ])
                            }

                            user = {{
                                first_name: userCard?.first_name ?? '',
                                last_name:  userCard?.last_name ?? '',
                                full_name:  userCard?.first_name && userCard?.last_name ? `${userCard?.first_name} ${userCard?.last_name}` : '',
                                phone:      userCard?.phone?.contact ?? '',
                                company:    userCard?.company ?? '',
                                position:   userCard?.position ?? '',
                                email:      userEmail,

                                first_name_base64: this.getBase64( userCard?.first_name ),
                                last_name_base64:  this.getBase64( userCard?.last_name ),
                                full_name_base64:  this.getBase64([ userCard?.first_name, userCard?.last_name ]),
                                phone_base64:      this.getBase64( userCard?.phone?.contact ),
                                company_base64:    this.getBase64( userCard?.company ),
                                position_base64:   this.getBase64( userCard?.position ),
                                email_base64:      this.getBase64( userEmail ),

                                user_id: user?.id,
                                card_id: userCard?.id ?? data?.id,
                                link_id: sharedLinks?.[ 0 ]?.id || sharedData?.id,
                                logo:    data?.company_design?.square_logo || data?.company_owner?.square_logo,
                                nearestEvents
                            }}

                            checks = {{
                                loggedin:      !isOwnMigrated,
                                design:        shared ? false : ( !!data?.company_design || !!activated?.company_design ),
                                digital:       isDigital,
                                promo:         isPromo,
                                nearestEvents: nearestEvents && Array.isArray( nearestEvents ) && nearestEvents.length > 0,
                                public:        !!customDesigns && (
                                    shared
                                        ? (
                                            !steps?.data?.exchange?.card?.company_owner || steps?.data?.exchange?.card?.company_owner?.id === config.publicEmailCompany
                                        )
                                        : (
                                            activated
                                                ? !activated?.company_owner || activated?.company_owner?.id === config.publicEmailCompany
                                                : !data?.company_owner || data?.company_owner?.id === config.publicEmailCompany
                                        )
                                )
                            }}

                            designs = { forceNearestEvent ? customDesigns || [] : customDesigns }

                            onAvatar = { this.uploadAvatar }
                            requestCorporate = { this.props.updateClaimCorp }
                            updateLinkEvent={ this.props.updateLinkEvent }
                            setDesign = { this.setDesign }
                            onFinish = {() => {
                                window.history.replaceState({}, '', window.location.origin + window.location.pathname )
                                this.props.reloadCard( generate.uniq())
                            }}
                        />
                    )
                }
                <PublicLoader
                    visible={
                        !error &&
                        !active &&
                        (
                            !data ||
                            ( account && !user ) ||
                            ( !!user?.id && !userCard && user?.cards?.length > 0 ) ||
                            ( downloadWallet && !downloaded ) ||
                            ( hasDownload && downloadType === 'google_wallet' )
                        )
                    }
                />
                {isMine ? (
                    <div
                        className={classNames(
                            'single-redirect-plate',
                            singleRedirect && 'visible',
                        )}
                    >
                        {t( 'publicCard.SINGLE_REDIRECT' )}
                        <span
                            className='link'
                            onClick={() =>
                                ( window.location.href = data.single_contact.contact )
                            }
                        >
                            {t( 'publicCard.OPEN_REDIRECT' )}
                        </span>
                    </div>
                ) : (
                    <div
                        className={classNames(
                            'single-redirect-plate',
                            singleRedirect && 'visible',
                        )}
                    >
                        <Spinner />
                        {t( 'publicCard.REDIRECTING' )}
                        <span className='link' onClick={this.stopRedirect}>
                            {t( 'publicCard.STOP_REDIRECT' )}
                        </span>
                    </div>
                )}
            </StaticPage>
        )
    }
}

export default connector( withRouter( PublicCard ))

import { useSelector, useDispatch } from 'react-redux'
import moment                       from 'moment'

import TabeeAuth from '@tabee/frontend-auth'

import { authActions } from 'services'
import { t }           from 'tools'

const PublicCardFooter = () => {
    const
        dispatch = useDispatch(),
        user = useSelector( state => state.user?.info ),
        signIn = e => {
            e.preventDefault()
            TabeeAuth.SSOSignIn( '/', window.location.pathname )
        },
        signOut = e => {
            e.preventDefault()
            dispatch( authActions.signOut())
        }

    return (
        <footer className="public-card-footer">
            <ul className="public-card-afterwords">
                <li>{t( 'publicCard.FOOTER_TABEE_TEAM' )}</li>
                <li>© { moment().format( 'YYYY' ) } Tabee</li>
                {
                    user
                        ? <li><a href='#' onClick={signOut}>{ t( 'admin.signOut' ) }</a> • <a href='#' onClick={signIn}>{ t( 'admin.switchCard' ) }</a></li>
                        : <li><a href='#' onClick={signIn}>{ t( 'auth.loginSignIn' ) }</a></li>
                }
                <li><a href="mailto:hello@tabee.mobi">hello@tabee.mobi</a></li>
            </ul>
            {/*<ul className="public-card-social">
                <li>
                    <a target="_blank" rel="noreferrer" href="https://tabee.app/php/tb-twitter-mail.php">
                        <picture>
                            <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/dark/icon-twitter.png'} media="(prefers-color-scheme: dark)" />
                            <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/icon-twitter.png'} media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
                            <img src={'https://server.tabee.mobi/static/tabee/images/mails/icon-twitter.png'} loading="lazy" width="40" height="40" alt="Twitter" />
                        </picture>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" href="https://tabee.app/php/tb-facebook-mail.php">
                        <picture>
                            <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/dark/icon-facebook.png'} media="(prefers-color-scheme: dark)" />
                            <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/icon-facebook.png'} media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
                            <img src={'https://server.tabee.mobi/static/tabee/images/mails/icon-facebook.png'} loading="lazy" width="40" height="40" alt="Facebook" />
                        </picture>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" href="https://tabee.app/php/tb-linkedin-mail.php">
                        <picture>
                            <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/dark/icon-linkedin.png'} media="(prefers-color-scheme: dark)" />
                            <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/icon-linkedin.png'} media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
                            <img src={'https://server.tabee.mobi/static/tabee/images/mails/icon-linkedin.png'} loading="lazy" width="40" height="40" alt="Linkedin" />
                        </picture>
                    </a>
                </li>
            </ul>*/}
        </footer>
    )
}

export default PublicCardFooter

import axios                                              from 'axios'
import { asyncUpdate as _asyncUpdate, update as _update } from 'reduxigen'

import config from 'config'

const
    { CancelToken } = axios,
    cancels = {}

export const fix = ({ count, page, ...params }) => {
    const
        res = { ...params }

        ;( count ) && ( res.size = count )
    ;( page ) && ( res.page = page - 1 )

    return res
}

export const options = () =>
    ({
        validateStatus: null,
        headers:        storage.get( 'token' )
            ? { Authorization: `Token ${storage.get( 'token' )}` }
            : {}
    })

const cancel = name =>
    ({ cancelToken: new CancelToken( function executor ( token ) { cancels[ name ] = token }) })

export const get = ( endpoint, params, uniq, cfg ) => {
    const add = uniq || ''

    cancels[ endpoint + add ] && cancels[ endpoint + add ]()

    return axios.get(
        config.api.url + '/api/' + endpoint,
        {
            params,
            ...options(),
            ...cfg,
            ...cancel( endpoint + add )
        }
    )
}

export const remove = ( endpoint, cfg ) => axios.delete(
    config.api.url + '/api/' + endpoint,
    {
        ...options(),
        ...cfg
    }
)

export const post = ( endpoint, body, cfg ) => axios.post(
    config.api.url + '/api/' + endpoint,
    body,
    {
        ...options(),
        ...cfg
    }
)

export const patch = ( endpoint, body, cfg ) => axios.patch(
    config.api.url + '/api/' + endpoint,
    body,
    {
        ...options(),
        ...cfg
    }
)

export const storage = {
    get:    key => localStorage.getItem( `${config.ui.prefix}_${key}` ),
    set:    ( key, value ) => localStorage.setItem( `${config.ui.prefix}_${key}`, value ),
    remove: key => localStorage.removeItem( `${config.ui.prefix}_${key}` )
}

const saveOne = ( key, value ) =>
    (
        typeof value === 'object' && !!value
            ? Object.keys( value ).forEach( sub => saveOne( `${key}_${sub}`, value[ sub ]))
            : localStorage.setItem( key.replace( 'storage', config.ui.prefix ).replace( /\./g, '_' ), value )
    ),

      savior = ( key, handler ) => ( value, state ) => {
          if ( typeof value === 'object' ) {
              if ( value.status && value.data ) {
                  Object.keys( value.data ).forEach( sub => {
                      saveOne( 'storage.' + sub, value.data[ sub ])
                  })
              }
          } else {
              saveOne( key, value )
          }

          return handler( value, state )
      }

export const update = ( name, req, parser ) => _update( name, req, parser )

export const asyncUpdate = ( name, requester, parser ) => {
    return _asyncUpdate(
        name,
        params =>
            new Promise(
                resolve =>
                    requester( params )
                        .then( event => resolve( event ))
                        .catch(
                            event => {
                                if ( axios.isCancel( event )) { return }

                                return (
                                    resolve(
                                        typeof event === 'object' && event.status
                                            ? event
                                            : {
                                                data: {
                                                    ...event,
                                                    error:   true,
                                                    data:    event.data || null,
                                                    code:    event.message,
                                                    message: event.toJSON()
                                                }
                                            }
                                    )
                                )
                            }
                        )
            ),
        ( event, state ) => {
            const
                nstate = {
                    ...state,
                    networkError: false
                }

            if ( event.status === 401 ) {
                if (
                    window.location.pathname.toLowerCase() !== '/login' &&
                    window.location.pathname.toLowerCase().indexOf( 'u/' ) < 0 &&
                    window.location.pathname.toLowerCase().indexOf( 'p/' ) < 0 &&
                    window.location.pathname.toLowerCase().indexOf( 'digital/' ) < 0 &&
                    window.location.pathname.toLowerCase().indexOf( '/digital' ) < 0 &&
                    window.location.pathname.toLowerCase().indexOf( 'found/' ) < 0
                ) {
                    window.location.href = `${config.sso.url}?redirect_uri=${encodeURIComponent( window.location.host )}/auth/sso&return_url=${encodeURIComponent( window.location.pathname )}`
                    return
                } else {
                    storage.remove( 'token' )
                    storage.remove( 'user_id' )
                    storage.remove( 'account' )

                    nstate.auth = {
                        ...nstate.auth,
                        info:       { token: null },
                        logged:     false,
                        lastTry:    -1,
                        msg:        '',
                        registered: nstate.auth.registered
                    }

                    nstate.user = {
                        ...state.user,
                        info:    {},
                        current: null,
                        account: null
                    }
                }
            }

            if ( event.data.error ) {
                nstate.networkError = { status: event.status }
            }

            return parser( event, nstate )
        }
    )
}

export const requestAndSave = ( key, req, handler ) => asyncUpdate(
    key,
    req,
    savior( key, handler )
)

export const updateAndSave = ( key, handler ) => _update(
    key,
    savior( key, handler )
)

import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import config from 'config'

const DownloadWallet = () => {
    const
        { type } = useParams()

    useEffect(() => {
        const
            dstring = type ? `?download=${type}` : ''

        window.location.href = `${config.sso.url}${dstring}`
    }, [])

    return <></>
}

export default DownloadWallet

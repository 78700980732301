import { useEffect } from 'react'

import { Center, PublicLoader } from 'components'
import config                   from 'config'

const AddContact = () => {
    const
        urlParams = new URLSearchParams( window.location.search ),
        contact = urlParams.get( 'contact' ),
        type = urlParams.get( 'contact_type' )

    useEffect(() => {
        if ( contact ) {
            window.location.href = `${config.sso.url}?addcontact=${encodeURIComponent( contact )}${ type ? `&contact_type=${type}` : '' }`
        } else {
            window.location.href = config.sso.url
        }
    }, [])

    return (
        <Center>
            <PublicLoader />
        </Center>
    )
}

export default AddContact

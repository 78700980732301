
import { Button, Input } from 'antd'
import classNames        from 'classnames'

import { Spinner, LabelIn } from 'components'
import { t }                from 'tools'

import SocialAuth from '../SocialAuth'

const LiteDataForm = ({ form, error, working, socials, buttonStyle, onUpdate, onDone, onGoogle, onFacebook, onLinkedin, onApple, onVK, onYandex }) => {
    return (
        <form className="pcf-form" onSubmit={onDone}>
            <div className="pcf-form-item">
                <LabelIn label={t( 'publicCard.ENTER_BUSINESS_EMAIL' )}>
                    <Input
                        value = { form.email }
                        disabled = { working }
                        className = {classNames( 'force-lowercase', error.email && 'has-error' )}

                        onChange = { e => onUpdate( 'email', e.target.value ) }
                    />
                </LabelIn>
                <SocialAuth
                    config = { socials }

                    onGoogle = { onGoogle }
                    onFacebook = { onFacebook }
                    onLinkedin = { onLinkedin }
                    onApple = { onApple }
                    onVK = { onVK }
                    onYandex = { onYandex }
                />
            </div>
            <div className="pcf-form-item">
                <LabelIn label={`${t( 'publicCard.PERSONAL_EMAIL' )} ${t( 'publicCard.OPTIONAL' )}`}>
                    <Input
                        value = { form.personal_email }
                        type = "email"
                        autoComplete = "email"
                        disabled = { working }
                        className = { classNames( 'force-lowercase', error.personal && 'has-error' ) }

                        onChange = { e => e.target.value !== form.email && onUpdate( 'personal_email', e.target.value ) }
                    />
                </LabelIn>
            </div>
            <div className="pcf-form-item">
                <LabelIn label={t( 'publicCard.ENTER_YOUR_PHONE' )}>
                    <Input
                        value = { form.phone }
                        disabled = { working }
                        autoComplete = "tel"
                        className = {classNames( 'force-lowercase', error.phone && 'has-error' )}

                        onChange = { e => onUpdate( 'phone', e.target.value.replace( /[^0-9\- +]/g, '' ) === '+' ? '' : `+${e.target.value.replace( /[^0-9\- ]/g, '' )}` ) }
                    />
                </LabelIn>
            </div>
            <div className="pcf-form-item">
                <LabelIn label={t( 'publicCard.NAME' )}>
                    <Input
                        value = { form.first_name }
                        disabled = { working }
                        autoComplete = "given-name"
                        className = { error.first_name && 'has-error' }

                        onChange = { e => onUpdate( 'first_name', e.target.value ) }
                    />
                </LabelIn>
            </div>
            <div className="pcf-form-item">
                <LabelIn label={t( 'publicCard.SURNAME' )}>
                    <Input
                        value = { form.last_name }
                        disabled = { working }
                        autoComplete = "family-name"
                        className = { error.last_name && 'has-error' }

                        onChange = { e => onUpdate( 'last_name', e.target.value ) }
                    />
                </LabelIn>
            </div>
            <div className="pcf-form-item">
                <LabelIn label={t( 'publicCard.JOB_TITLE' )}>
                    <Input
                        value = { form.position }
                        disabled = { working }
                        autoComplete = "organization-title"
                        className = { error.position && 'has-error' }

                        onChange = { e => onUpdate( 'position', e.target.value ) }
                    />
                </LabelIn>
            </div>
            <div className="pcf-form-item">
                <LabelIn label={t( 'publicCard.COMPANY_NAME' )}>
                    <Input
                        value = { form.company }
                        disabled = { working }
                        autoComplete = "organization"
                        className = { error.company && 'has-error' }

                        onChange = { e => onUpdate( 'company', e.target.value ) }
                    />
                </LabelIn>
            </div>
            <small>
                {t( 'publicCard.AGREEMENT_START', { buttonText: t( 'publicCard.CREATE_BUSINESS_CARD' ) })} <a href="https://tabee.app/tos/" target="_blank" rel="noreferrer">{t( 'publicCard.AGREEMENT_TERMS' )}</a>{t( 'publicCard.AGREEMENT_AND' )}<a href="https://tabee.app/privacy-policy/" target="_blank" rel="noreferrer">{t( 'publicCard.AGREEMENT_PRIVACY' )}</a>.
            </small>
            <Button type="primary" disabled={ working } style={ buttonStyle } onClick={onDone}>
                {
                    working
                        ? (
                            <Spinner color={buttonStyle?.color || '#fff'} />
                        )
                        : t( 'publicCard.CREATE_BUSINESS_CARD' )
                }
            </Button>
        </form>
    )
}

export default LiteDataForm



import './label-in.scss'

const LabelIn = ({ label, children }) => {
    return (
        <div className="label-in">
            { children }
            <span className="label-in-label">
                { label }
            </span>
        </div>
    )
}

export default LabelIn
